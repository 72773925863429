<template>
  <div>
    <b-table
      responsive
      :items="tableData"
      :fields="tableColumns"
      show-empty
      empty-text="Aucun resultats"
      :busy="isLoadingTableData"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>
      <template #cell(id)="data">
        <strong>{{
          (paginationData.perPage * (paginationData.currentPage - 1) || 0) +
          data.index +
          1
        }}</strong>
      </template>
      <template #cell(ref)="data">
        <b-avatar variant="dark"> <feather-icon icon="ImageIcon" /> </b-avatar
        >{{ "  " }}
        {{ data.item.ref }}
      </template>
      <template #cell(etablissement)="data">
        {{
          data.item.etablissement && data.item.etablissement.nomEtablissement
        }}
      </template>
      <template #cell(actions)="data">
        <b-button
          variant="primary"
          :to="{
            name: 'etablissements-agents-details',
            params: { agentId: data.item._id },
          }"
        >
          <feather-icon icon="EyeIcon" />
          <!-- TARINUS met le nom de sa page create ici -->
          <span class="text-nowrap">Details</span>
        </b-button>
      </template>
    </b-table>
    <div class="d-flex justify-content-between mt-1" v-if="tableData && tableData.length > 1">
      <div class="d-flex flex-row"> 
        <span class="my-auto mr-1">Afficher </span>
        <div>
          <b-form-select
            v-model="paginationData.perPage"
            :options="optionsPerPage"
            size="sm"
            class="form-control col"
          />
        </div>
        <span class="my-auto ml-1">lignes </span>
      </div>

      <div>
        <b-pagination
          v-model="paginationData.currentPage"
          :total-rows="paginationData.total"
          :per-page="paginationData.perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>

      <div class="my-auto">
        <!-- <div class="d-flex flex-row">  -->
          <span class="my-auto">Affichage de {{paginationData.metaData.from}} à {{paginationData.metaData.to}} sur {{paginationData.total}} </span>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BPagination,
  BAvatar,
  BButton,
  BTable,
  BSpinner,
  BFormSelect,
  BCard,
} from 'bootstrap-vue';
import { mapGetters, mapActions } from 'vuex';

// store module and vuex utilities
import paramsStroreModule from "@/store/params";
import usersStoreModule from "@/store/users";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  setup() {
    const requiredStoreModules = [
      { path: "params", module: paramsStroreModule },
      { path: "users", module: usersStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules,$v: useVuelidate({ $lazy: true }) };
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  }, 
  name: 'ListFormsComponent',
  components: {
    BFormCheckbox,
    BFormSelect,
    BAvatar,
    BPagination,
    BButton,
    BTable,
    BSpinner,
    BCard,
  },
  props: { agentId: null, formType: null, },
  data() {
    return {
      filters: { options: {} },
      currentPage: 1,
      perPage: 10,
      rows: 100,
      tableData: null,
      filteredTableData: [],
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200],
      tableColumns: [
        { key: 'id', label: '#', sortable: true },
        { key: 'firstname', label: 'Nom', sortable: true },
        { key: 'lastname', label: 'Prenom', sortable: true },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
        },
        {
          key: 'etablissement',
          label: 'Etablissement',
          sortable: true,
        },
        {
          key: 'nbr_fiche',
          label: 'Nombre de fiche remplies',
          sortable: true,
        },
        'actions',
      ],
      isLoadingTableData: false,
    };
  },
  computed: {
    ...mapGetters('params', {
      typesRoles: 'getter_typesRoles',
    }),
    totalRows() {
      return (this.tableData && this.tableData.length) || 0;
    },
  },
  watch: {
    perPage: {
      immediate: true,
      handler(val, old) {
        if (this.tableData && this.tableData[val - 1]) {
          this.filteredTableData = this.customCloneDeep(
            this.tableData.slice(0, val - 1)
          );
        }
      },
    },
    tableData: {
      immediate: true,
      handler(val, old) {
        if (this.tableData) {
          this.filteredTableData = this.customCloneDeep(this.tableData);
        }
      },
    },
    selectedCategorie: {
      immediate: true,
      async handler(val, old) {
        await this.action_fetchTypesRoles();
        this.getListUsers();
      },
    },
    'paginationData.currentPage': {
      handler(val, old) {
        this.getListUsers()
      },
    },
    'paginationData.perPage': {
      handler(val, old) {
        this.getListUsers()
      },
    },
  },
  async mounted() {},
  methods: {
    ...mapActions('users', { action_fetchUsers: 'fetchUsers' }),
    ...mapActions('params', { action_fetchTypesRoles: 'fetchTypesRoles' }),
    getListUsers() {
      this.isLoadingTableData = true;
      const userRole = this.typesRoles.find(
        (e) => e.nom === 'Agent Etablissement'
      );
      console.log(this.$props);
      const userRoleId = userRole._id;
      const params = {
        options: {
          role: userRoleId,
          ...(this.etablissementId && { etablissement: this.etablissementId }),
        },
        page: this.paginationData.currentPage - 1,
        perPage: this.paginationData.perPage,
      };
      this.action_fetchUsers(params)
        .then((response) => {
          this.tableData = response.data.admins || [];
          this.paginationData.total = response.data.total || 0;
          this.isLoadingTableData = false;

          if(this.paginationData.metaData.from == 0) this.paginationData.metaData.from += 1
          else this.paginationData.metaData.from = (this.paginationData.perPage * this.paginationData.currentPage) - (this.paginationData.perPage)
          this.paginationData.metaData.to = (this.paginationData.perPage * this.paginationData.currentPage) - this.paginationData.perPage + this.tableData.length
        })
        .catch((err) => {
          this.isLoadingTableData = false;

          console.log('error-users-list', err);
        });

      // setTimeout(() => {
      //   this.tableData = new Array(200)
      //     .fill({
      //       nom: 'Nom',
      //       prenom: 'Prenom',
      //       email: 'user@user.com',
      //     })
      //     .map((e, i) => {
      //       const temp = JSON.parse(JSON.stringify(e))
      //       return { ...temp, id: i }
      //     })

      //   this.isLoadingTableData = false
      // }, 2000)
    },
  },
};
</script>
<style lang="scss" scoped>
.link-wrapper {
  display: flex;
  height: 40px;
  align-items: center;
  gap: 10px;
  a {
    text-transform: uppercase;
  }
}
.footer,
.footer .per-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>

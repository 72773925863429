<template>
  <div>
    <b-card class="mb-1 d-flex">
      <div class="d-flex flex-wrap justify-content-between">
        <div>
          <h2>Details d'un Agent du MTCA</h2>
        </div>
        <div>
          <ButtonArchiverUtilisateur
            v-if="agentId"
            :utilisateur-id="agentId"
            redirect-route="agents-listes-des-agents"
          />
          <!-- <ButtonArchiver :agent-etablissement-id="agentId" /> -->
        </div>
      </div>
      <!-- <div class="text-right" v-if="false">
        <ButtonArchiver :agent-etablissement-id="agentId" />
      </div> -->
    </b-card>
    <h1 v-if="!isFetchingAgentDetails && !itemDetails">
      Aucun agent ne correspond à cet enregistrement
    </h1>
    <content-loader
      v-if="isFetchingAgentDetails"
      view-box="0 0 400 460"
      :speed="2"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    >
      <circle
        cx="29"
        cy="30"
        r="17"
      />
      <rect
        x="58"
        y="18"
        rx="2"
        ry="2"
        width="140"
        height="10"
      />
      <rect
        x="58"
        y="34"
        rx="2"
        ry="2"
        width="140"
        height="10"
      />
      <rect
        x="-7"
        y="60"
        rx="2"
        ry="2"
        width="408"
        height="132"
      />
    </content-loader>
    <b-card
      v-if="itemDetails"
      class="mb-0"
    >
      <b-row>
        <b-col sm="6">
          <!-- <h4>Détails de l'agent</h4> -->
          <hr>
          <p><strong>Nom : </strong>{{ itemDetails.firstname }}</p>
          <p><strong>Prenom : </strong>{{ itemDetails.lastname }}</p>
          <p><strong>Email : </strong>{{ itemDetails.email }}</p>
          <p><strong>Poste : </strong>{{ itemDetails.poste }}</p>
          <!-- <p>
            <strong>Nombre de fiches remplies : </strong>
            <b-badge
              pill
              variant="primary"
            >
              {{ forms.length || 0 }}
            </b-badge>

          </p> -->
        </b-col>
      </b-row>

      <hr class="my-1">
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormFile,
  BSpinner,
  BBadge,
  BTable,
  VBTooltip,
  VBModal,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useVuelidate from '@vuelidate/core'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import AppLogo from '@/components/AppLogo.vue'
import RecaptchaWidget from '@/components/RecaptchaWidget.vue'
import { ContentLoader } from 'vue-content-loader'
import ButtonArchiver from '@/views/apps/utilisateurs/etablissements/agents/components/ButtonArchiver.vue'
import ButtonArchiverUtilisateur from '@/views/apps/utilisateurs/administration/component/ButtonArchiverUtilisateur.vue'
// store module and vuex utilities
import etablissementsStroreModule from '@/store/etablissements'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'
import ListFormsComponent from './component/ListFormsComponent.vue'

export default {
  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStroreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules, $v: useVuelidate({ $lazy: true }) }
  },
  components: {
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormFile,
    BBadge,
    BTable,

    // validations
    AppLogo,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    RecaptchaWidget,
    BSpinner,
    BRow,
    BCol,
    ContentLoader,
    ListFormsComponent,
    ButtonArchiver,
    ButtonArchiverUtilisateur,
  },

  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      itemDetails: null,
      forms: [],
      isFetchingAgentDetails: false,
      isLoadingTableData: false,
      tableColumns: [
        { key: '_id', label: '#', sortable: true },
        { key: 'reference', label: 'Référence', sortable: true },
        { key: 'createdAt', label: 'Date', sortable: true },
        { key: 'nomEtablissement', label: 'Etablissement', sortable: true },
        { key: 'statut', label: 'Statut', sortable: true },
        { key: 'actions' },
      ],
    }
  },
  computed: {
    etablissementDetails() {
      return this.itemDetails && this.itemDetails.etablissement
    },
    agentId() {
      return this.itemDetails && this.itemDetails._id
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.isFetchingAgentDetails = true
        const { agentId } = val.params
        this.isLoadingTableData = true
        this.getAgent(agentId)
          .then(res => {
            const { resource, formsCreate } = res.data || {}
            this.itemDetails = resource
            if (formsCreate) {
              formsCreate.map(item => {
                this.forms = this.forms.concat(item.data)
              })
            }
            this.isFetchingAgentDetails = false
            this.isLoadingTableData = false
          })
          .catch(err => {
            this.isFetchingAgentDetails = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.message || 'Une erreur est survenue',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            console.log(err)
          })
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },

  mounted() {},
  methods: {
    ...mapActions('etablissements', {
      getEtablissement: 'getEtablissement',
      getAgent: 'getAgent',
    }),
  },
}
</script>

<style lang="scss" scoped>
.img-ctn {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  .img {
    height: 100px;
    width: 100px;
    background: rgba(4, 4, 4, 0.583);
    border-radius: 5px;
  }
}
</style>
